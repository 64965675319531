<template>
<dash-page-new
    :title="$t('ChatBotConstructor') "
    :navigation-route="businessDashLink('chatbots')"
    :header-route="businessDashLink('chatbots')"
    no-side>

  <template #header_action>
    <v-btn-toggle :background-color="wsLIGHTCARD" :color="wsACCENT"  @change="structureCards" mandatory v-model="navigation">
      <v-btn small class="noCaps" value="is_guest_user">{{ $t('NonRegisteredUser') }}</v-btn>
      <v-btn small class="noCaps" value="is_client_user">{{ $t('ClientUser') }}</v-btn>
<!--      <v-btn small class="noCaps" value="is_owner_user">{{ $t('AdminUser') }}</v-btn>-->
    </v-btn-toggle>
  </template>

  <template #defaultDesktop>
    <div   v-resize="drawLines" style="position: relative; overflow-y: hidden"  id="chat_bot_constructor_container"  >
      <div class="d-flex">
        <!-- Card  -->
        <div v-for="(column,i) in structure" :key="i" class="mr-10 d-flex align-center">
          <!-- Card  -->
          <div>
            <!-- Card  -->
            <v-sheet v-for="(card,j) in column" elevation="3"
                     v-resize="drawLines"
                     :key="card.id"
                     :ref="card.id"
                     :id="'card_' + card.id"
                     class="wsRoundedHalf ma-3"
                     width="250" >

              <!-- Card Header  -->
              <v-sheet :color="getCardHeaderColor(card)" dark class="wsRoundedHalfTop px-3 py-2 d-flex justify-space-between align-center">
                <div class="d-flex align-center">
                  <v-icon class="mr-1" small>mdi-account</v-icon>
                  <h6 class="font-weight-medium">
                    {{ getCardTypeText(card)  }}
                    <v-sheet color="transparent" v-if="card.type === 'course'" :set="course = getCourse(card)" >
                      {{ course.text }}
                    </v-sheet>
                  </h6>
                </div>
                <v-btn v-if="getCardDeletePermission(card)" @click="displayDeleteCard(card)" small icon><v-icon small>mdi-delete</v-icon></v-btn>
              </v-sheet>
              <!-- Card Content -->
              <div class="pa-3">
                <!-- Card Image -->
                <div v-if="card.image" class="d-flex mb-3">
                  <div>
                    <v-hover v-slot="{hover}">
                      <v-sheet class="wsRoundedHalf mt-2" >
                        <v-img class="wsRoundedHalf" :height="card.type !== 'link' ? '150' : '100'"  width="223" :src="card.image" >
                          <v-sheet  v-if="hover && card.type !== 'link' " v-ripple
                                    @click="displayDeleteImage(card)"
                                    class="fill-height d-flex align-center justify-center"
                                    :class="hover ? 'pointer' : ''"
                                    color="#00000055" dark >
                            <v-icon small class="mr-1">mdi-delete</v-icon>
                            <h6 class="font-weight-medium">{{ $t('Delete') }}</h6>
                          </v-sheet>
                        </v-img>
                      </v-sheet>
                    </v-hover>
                  </div>
                </div>
                <div v-if="getCourse(card).img">
                  <v-img class="wsRoundedHalf" height="150"  width="223" :src="getCourse(card).img" />
                </div>
                <!-- Course text-->
                <div v-if="getCourse(card).value">
                  <h6>{{ getCourse(card).text }}</h6>
                  <h6 class="font-weight-light">{{ getCourse(card).short_description }}</h6>
                </div>
                <!-- Card Message -->
                <div class="d-flex mb-3">
                  <!-- Card Text-->
                  <v-hover v-slot="{hover}">
                    <v-sheet  class="transition-ease-in-out pa-1 " style="border-radius: 5px" :color="hover ? wsLIGHTCARD : 'transparent'">
                      <h6 contenteditable="plaintext-only"
                          @focusout="saveCardText($event , card); hover = false"
                          @focus="hover = true"
                          class="font-weight-medium mt-1 card-message transition-ease-in-out "
                          v-html="getCardText(card.text)" >
                      </h6>
                    </v-sheet>
                  </v-hover>
                </div>

                <!-- Card Buttons -->
                <v-sheet v-for="k in getButtonsRows(card.buttons)" :key="'button' + i + j + k"
                         :id=" i + '_' +card.id + '_' + 'button_row_' + k  "
                         color="transparent">
                  <div class="d-flex just">
                    <v-sheet v-for="(button,l) in getButtonsRowColumns(card.buttons,k)"

                             style="width: 100%"
                             :key="'button' + i + j + k + l"
                             class="py-1" color="transparent">
                      <v-hover v-slot="{hover}">

                        <!-- Button-->
                        <v-sheet
                            @click="displayEditButton(card , button , l )"
                            :color="wsLIGHTCARD"
                            max-width="270"
                            style="border-radius : 5px ; "
                            class="pointer fill-height "
                            v-ripple
                            block  elevation="0">

                          <v-sheet :color="!hover ? 'transparent' : '#00000022'"
                                   style="border-radius : 5px ;transition: all 0.3s ease  "
                                   class="py-1 d-flex fill-height "
                                   :class="button.action !== 'url' ? 'justify-center align-center' : 'justify-space-between align-baseline' ">

                            <div v-if="button.action === 'url' "></div>
                            <h6 class="font-weight-medium text-center">{{ button.text }}</h6>
                            <v-icon v-if="button.action === 'url' " x-small>mdi-arrow-top-right</v-icon>

                          </v-sheet>

                        </v-sheet>

                      </v-hover>
                    </v-sheet>
                  </div>
                </v-sheet>

                <!-- Courses List Buttons -->
                <div v-if="card.type === 'courses'" class="mb-3">

                  <v-sheet  v-for="course in getActiveCourses()" :key="course.value"
                            :color="wsLIGHTACCENT"
                            max-width="270"
                            style="border-radius : 5px ; "
                            class="py-1 d-flex mx-1 mt-2 justify-center fill-height align-center"
                            v-ripple
                            elevation="0">
                    <h6 class="font-weight-medium text-center">{{ course.text }}</h6>
                  </v-sheet>

                </div>

                <!-- Add Element Button -->
                <ft-select @input="displayAddElement($event, i, card)" :items="getAddElements(card)">
                  <v-btn :color="wsACCENT"
                         height="25"
                         class="noCaps mt-3"
                         block text x-small >
                    <div class="d-flex align-center">
                      <v-icon style="padding-top:1px" x-small>mdi-plus</v-icon>
                      {{ $t('Add') }}
                    </div>


                  </v-btn>
                </ft-select>
              </div>

            </v-sheet>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template #defaultMobile>
    <div class="fill-height d-flex align-center justify-center">
      <h3 class="font-weight-light text-center"> {{ $t('ChatBotsConstructorMobileScreenWarning') }}</h3>
    </div>
  </template>

  <template #dialog>
    <!-- Add Button Dialog-->
    <wsDialog v-model="displayButtonDialog"
              :title="newButton ? $t('NewButton') : $t('EditButton')"
              :loading="LOADING"
              :show-delete="deleteButtonDisplayCondition"
              @delete="deleteButton"
              @save="newButton ? addButton() : editButton()" >

      <h5 class="mb-3">{{ $t('ButtonText') }}</h5>
      <v-text-field v-model="entityData.text" :placeholder="$t('ButtonText') " dense outlined></v-text-field>

      <div v-if="newButton">
        <h5 class="mb-3">{{ $t('MessageType') }}</h5>
        <v-select v-model="entityData.type" :items="buttonActionsSelect" dense outlined :placeholder="$t('Action') " />
      </div>

      <!-- Data : Course Selector -->
      <v-expand-transition>
        <div v-if="entityData.type === 'course'" >
          <h5  class="mb-3">{{ $t('Course_') }}</h5>
          <v-autocomplete v-model="entityData.data" :items="coursesSelect" :placeholder="$t('Course')" dense outlined hide-details />
        </div>
      </v-expand-transition>

      <!-- Data : Url -->
      <v-expand-transition>
        <div v-if="entityData.type === 'url' || entityData.action === 'url' " >
          <h5  class="mb-3">{{ $t('Link') }}</h5>
          <v-text-field v-model="entityData.data" placeholder="https://westudy.ua" dense outlined hide-details />
        </div>
      </v-expand-transition>

      <!-- New Row Selector-->
      <v-switch v-if="getNewRowDisplayCondition() && newButton" v-model="entityData.new_row" :label="$t('FromNewRow')"></v-switch>

    </wsDialog>
    <!-- Add Image Dialog-->
    <wsDialog v-model="displayImageDialog"
              :title="$t('AddImage')"
              :show-save="false"
              :loading="LOADING"
    >
      <ws-image-upload @upload="addNewImage"> </ws-image-upload>
    </wsDialog>
    <!-- Delete Card Dialog-->
    <wsDialog v-model="displayDeleteCardDialog"
              :title="$t('DeleteMessage')"
              :show-save="false"
              :confirm-delete-text="$t('DeleteMessageConfirmation')"
              display-confirm-delete
              @delete="deleteCard"
              :loading="LOADING" />
    <!-- Delete image Dialog-->
    <wsDialog v-model="displayDeleteCardImageDialog"
              :title="$t('DeleteMessageImage')"
              :show-save="false"
              :confirm-delete-text="$t('DeleteMessageImageConfirmation')"
              display-confirm-delete
              @delete="deleteCardImage"
              :loading="LOADING" />
  </template>


</dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import LeaderLine from 'leader-line-vue'
import wsImageUpload from "@/components/UI/wsImageUpload";
import resize from 'vue-resize-directive'


export default {
  name: "ChatBotConstructor",
  components: {
    wsImageUpload,
  },
  directives: {
    resize,
  },
  data() {
    return {
      navigation : 'is_guest_user',
      items : [],
      structure : [],
      displayButtonDialog : false,
      displayImageDialog : false,
      displayDeleteCardDialog : false,
      displayDeleteCardImageDialog : false,
      newButton : false,
      newImage : false,
      entityData : {},
      lines : [],
      coursesSelect : [],
      selectetButtonColumnIndex : 0,

      bufferCard : {},
    }
  },
  computed: {
    buttonActionsSelect() {
      let items = [
        { text : this.$t('Message')        , value : 'card' },
        { text : this.$t('CoursesList')    , value : 'courses' },
        { text : this.$t('Course_')         , value : 'course' },
        { text : this.$t('Link')           , value : 'url' },
        { text : this.$t('BotLinkAccount') , value : 'link' },
      ]

      if ( this.navigation === "is_admin_user") {
        items.push( { text : this.$t('ActiveLeads') , value : 'admin_leads' } )
        items.push( { text : this.$t('Orders') , value : 'admin_orders' } )
      }

      return  items
    },
    deleteButtonDisplayCondition() {
      return this.entityData.action === 'url' && !this.newButton
    }
  },
  methods : {
    ...mapActions('chatbots', [
      'GET_CHATBOTS_CARDS',
      'EDIT_CARD',
      'DELETE_CARD',
      'ADD_CARD_BUTTON',
      'DELETE_CARD_BUTTON',
    ]),
    ...mapActions('templates', [
      'UPLOAD_IMAGE'
    ]),
    ...mapActions('courses', ['GET_COURSES_SELECT']),

    // Display Dialogs Functions
    displayAddElement($event , column , card) {

      if ( $event === 'button') {
        this.displayNewButton(column , card)
      }
      if ( $event === 'image') {
        this.displayAddImage( card )
      }

    },
    displayNewButton( column , card) {
      this.entityData = {
        type : 'card',
        text : this.$t('NewButtonText'),
        new_row : true
      }
      this.newButton = true
      this.displayButtonDialog = true
      this.bufferCard = card
    },
    displayAddImage( card) {
      this.entityData = {}
      this.newImage = true
      this.displayImageDialog = true
      this.bufferCard = card
    },
    displayEditButton( card , button , l) {
      this.bufferCard = card
      this.newButton = false
      this.entityData = button
      this.displayButtonDialog = true
      this.selectetButtonColumnIndex = l
    },
    displayDeleteCard( card ) {
      this.displayDeleteCardDialog = true
      this.bufferCard = card
    },
    displayDeleteImage( card ) {
      this.displayDeleteCardImageDialog = true
      this.bufferCard = card
    },
    // Constructor Functions
    saveCardText( $event,  card ) {
      card.text = $event.target.innerHTML
          .replace(/&nbsp;/g, "")
          .replace(/<br>/g, "\n")
      this.EDIT_CARD( card )
    },
    async addNewImage(file) {
      let result = await this.UPLOAD_IMAGE(file)
      if ( !result ) { return }
      this.bufferCard.image = result.url
      this.EDIT_CARD( this.bufferCard )
      this.drawLines()
      this.displayImageDialog = false

    },
    async addButton() {

      if ( this.entityData.type === 'url' ) {
        let regex = "https?://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]"
        const urlPattern = new RegExp(regex);
        if ( !urlPattern.test(this.entityData.data) ) {
          return this.notify(this.$t('PleaseEnterValidLink'))
        }

      }
      if ( this.entityData.type === 'course') {
        if (!this.entityData.data) {
          return this.notify('PleaseEnterCourse')
        }
      }

      let row = 0

      if ( this.bufferCard.buttons ) {
        let currentRow = Math.max(...this.bufferCard.buttons.map( el => el.row))
        row = this.entityData.new_row ? (currentRow + 1) : currentRow
      }

      let data = {
        "button_row" : row,
        "button_text" : this.entityData.text,
        "button_action" : this.entityData.type,
        "button_data" : this.entityData.data,
        "card_id"     : this.bufferCard.id,
        "target_card_id" : null,
      }

      if ( this.entityData.button_data ) {
        data.button_data = this.entityData.button_data
      }
      if ( this.entityData.target_card_id ) {
        data.target_card_id = this.entityData.target_card_id
      }

      let result = await this.ADD_CARD_BUTTON(data)

      if ( !result ) { return  }

      this.initPage()
      this.displayButtonDialog = false

    },
    async editButton() {
      let result = await this.EDIT_CARD( this.bufferCard )
      if ( !result ) { return }
      this.displayButtonDialog = false
    },
    async deleteCard() {
      if ( !this.getCardDeletePermission(this.bufferCard) ) {
        return this.notify(this.$t('TelegramCardHasLinksDeletethemFirst'))
      }
      await this.DELETE_CARD( this.bufferCard )
      this.initPage();
      this.displayDeleteCardDialog = false
    },
    async deleteCardImage() {
      this.bufferCard.image = null
      await this.EDIT_CARD( this.bufferCard )
      this.displayDeleteCardImageDialog = false
      this.drawLines()
    },
    async deleteButton() {

      let data = {
        card_id : this.bufferCard.id,
        id : this.entityData.id
      }
      let result = await this.DELETE_CARD_BUTTON(data)
      if ( !result ) { return }


      await this.initPage()
      this.displayButtonDialog = false
    },
    // Card Constructor Init Functions
    structureCards() {

      let items = [];
      let buffer = JSON.parse(JSON.stringify(this.items))
      let rootCard = this.getRootCard(this.navigation)

      items.push( [ rootCard ]  )

      let columnsLength = 1

      for (let i = 0 ; i < columnsLength; i++ ) {

        let newColumn = false
        items[i].forEach(card => {

          if ( card.buttons ) {

            if (card.buttons.length > 0 ) {
              card.buttons.forEach(button => {
                if ( button.action === 'card' ) {

                  let index = this.findInBuffer(buffer , button.data)

                  if ( index !== -1 ) {

                    if ( !items[i+1] ) {
                      items.push([])
                    }
                    items[i+1].push(buffer[index])
                    buffer.splice(index, 1)
                    newColumn = true
                  }
                }
              })
            }

          }
        })

        if (newColumn ) {
          columnsLength++;
        }
      }

      this.structure = items

      this.$nextTick(() => {
            this.$nextTick(() => {
              this.drawLines()
            })
      })


    },
    getRootCard( userType ) {
      if ( this.items.length === 0 ) {
        return null
      }
      let item = this.items.find( el=> el[userType] && el.is_root)

      return item ?? null

    },
    getButtonId(button,cardId,i,j,k,l) {
      if ( button.action === 'card') {
        return 'button_' + button.data + '_' + cardId + '_' +i
      } else {
        return 'button_' + i + j + k + l
      }
    },
    findInBuffer(buffer , id ) {

      if ( buffer.length === 0 ) {
        return -1
      }

      return buffer.findIndex( el => el.id === id )

    },
    // Leader Line functions
    drawLines() {

      this.removeLines();
      let parent = document.getElementById('chat_bot_constructor_container')
      let offset = document.getElementById('chat_bot_constructor_container').getBoundingClientRect()

      this.structure.forEach((column,i) => {
        column.forEach(card=>{
          if (card.buttons) {
            card.buttons.forEach(button=>{
              if ( button.action === 'card') {
                let lineStart = i + '_' +card.id + '_' + 'button_row_' + (button.row +1)
                let lineEnd = 'card_' + button.data
                this.addLine(lineStart, lineEnd )
              }
            })
          }
        })
      })


     let lines = document.getElementsByClassName('leader-line');
      lines.forEach((line)=> {
       document.getElementById('chat_bot_constructor_container').appendChild(line )
       let left = parseInt(line.style.left.slice(0, -2)) - offset.left + parent.scrollLeft
        let top  = parseInt(line.style.top.slice(0, -2)) -  offset.top - window.scrollY
       line.style.left = left + 'px'
       line.style.top  = top +  'px '
      })

    },
    addLine(startId, endId) {
      let start = document.getElementById(startId)
      let end = document.getElementById(endId)

      if ( start && end ) {
        this.lines.push(LeaderLine.setLine(start, end, {
          startSocket: 'right',
          endSocket: 'left',
          size : 2,
          color : this.wsACCENT,
          parent : document.getElementById('chat_bot_constructor_container')
        } )) ;
      }

    },
    removeLines() {
      let lines = document.getElementsByClassName('leader-line');
      for (let i=lines.length; i >0; i--) {
        lines[i-1].remove()
      }
    },
    // Technical
    getAddElements(card) {
      let items = [
        { text : this.$t('Button') , value : 'button' },
      ];

      if ( !card.image ) {
        items.push({ text : this.$t('Image')  , value : 'image'  })
      }

      return items

    },
    getCardTypeText(card) {

      if ( card.is_root ) { return this.$t('MainMenu') }

      switch( card.type ) {
        case null : return this.$t('Message');
        case 'link' : return this.$t('BotLinkAccount');
        case 'courses' : return this.$t('CoursesList');
        case 'course' : return this.$t('Course');
        case 'student_study' : return this.$t('StudentStudy');
        case 'business_lead_default' : return this.$t('ConsultationRequest')
        default : return card.type
      }

    },
    getCardHeaderColor(card) {
      if ( card.is_root ) { return this.wsACCENT }
      switch(card.type) {
        case null : return this.wsDARKLIGHT
        default : return this.wsATTENTION
      }
    },
    getCardDeletePermission(card) {
      if ( card.is_root ) { return false }
      if ( card.buttons ) {
        let cardButtons = card.buttons.filter(el => el.action === 'card')
        if ( cardButtons.length > 0 ) {
          return false
        }
      }
      return true
    },
    getButtonsRows(buttons) {
      if ( !buttons ) {
        return 0
      }
      let rows = buttons.map(el=>el.row)

      return Math.max(...rows) + 1
    },
    getButtonsRowColumns(buttons , row ) {
      if ( !buttons ) {
        return 0
      }
      return buttons.filter(el=>el.row === row -1 )

    },
    getNewRowDisplayCondition() {

      if ( !this.bufferCard.buttons ) {
        return false
      }
      if ( this.bufferCard.buttons.filter( el=> el.row === this.bufferCard.buttons[this.bufferCard.buttons.length -1].row ).length  > 2 ) {
        return false
      }

      return true
    },
    getCardText(html) {
      return html.replace(/\n/g, "<br>")
    },
    getCourse(card) {

      if ( this.coursesSelect.length === 0 || card.type !== 'course' ) {
        return {}
      }

      let course = this.coursesSelect.find(el=> el.value === card.data )

      return course ? course : {}

    },
    getActiveCourses() {
      let items = []
      if ( this.coursesSelect.length  > 0 ) {
       items = this.coursesSelect.filter( el=> el.active)
      }
      return items
    },

    // Page Init
    async initPage() {
      let result = await this.GET_CHATBOTS_CARDS()
      if ( !result || result === true) { return }
      this.items = result
      this.structureCards()
    }
  },
  async created() {
    this.initPage()
    let result = await this.GET_COURSES_SELECT()
    if ( !result || result === true) { return }
    this.coursesSelect = result
  }
}
</script>

<style scoped>
.card-message  {
  line-height: 1.4;
  overflow-wrap: break-word;
  inline-size: 220px;
  outline: none;
}
</style>